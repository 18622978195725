import ReactGA from 'react-ga4';
ReactGA.send({hitType: "pageview", page: "/", title: "Home Page"});
const FormSuccess = () => {
  return (
    <div>
      <section id="Intro">
        <div className="discoverpianosection">
          <div className="container px-3">
            <div className="content">
              <div className="header-content w-75 mx-auto text-center">
                <h2>Thank you for your submitting the contact form.</h2>
                <h5> We will shortly reach out to you!</h5>
                <p className="mt-3 justify">Thank you so much for reaching out to us through our contact form. Your message is important to us, and we want to assure you that we typically respond within a few hours during our working hours. Please rest assured that your inquiry is in good hands, and we will get back to you as swiftly as possible. In the meantime, if you have any urgent concerns or additional questions, feel free to contact us directly. We appreciate your patience and look forward to assisting you promptly.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FormSuccess;