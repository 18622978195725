import './App.css';
import ReactGA from 'react-ga4';
import Footer from './components/Footer';
import Header from './components/Header';
import Contact from './components/Contact';
import Home from './components/Home';
import ErrorPage from './components/error-page';
import {  Routes, Route, useLocation  } from 'react-router-dom';
import AutoRepair from './components/AutoRepair';
import FleetMaintenance from './components/FleetMaintenance';
import Locksmith from './components/Locksmith';
import RepairInsurance from './components/RepairInsurance';
import ContactUs from './components/ContactUs';
import FormSuccess from './components/FormSuccess';
import GoogleAdLanding from './components/GoogleAdLanding';
import GoogleAdContactUs from './components/GoogleAdContactUs';
import GoogleAdSuccessPage from './components/GoogleAdSuccessPage';
import React, { useEffect } from 'react';

const TRACKING_ID = 'AW-16457593056'; 
ReactGA.initialize(TRACKING_ID);
ReactGA.send({hitType: "pageview", page: "/home"});
ReactGA._gaCommandSendPageview(document.location.pathname);


function App() {
  const location = useLocation();
  
  return (
    
    <main>
      <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="AutoRepair" element={<AutoRepair />} />
          <Route path="FleetMaintenance" element={ <FleetMaintenance />} />
          <Route path="Locksmith" element={<Locksmith /> } />
          <Route path="RepairInsurance" element={<RepairInsurance /> } />
          <Route path="ContactUs" element={ <ContactUs />} />
          <Route path="Success" element={ <FormSuccess />} />
          <Route path="*" element={<ErrorPage/>} />

          <Route path="Macungie" element={ <GoogleAdLanding />} />
          <Route path="Emmaus" element={ <GoogleAdLanding />} />
          <Route path="Zionsville" element={ <GoogleAdLanding />} />
          <Route path="Alburtis" element={ <GoogleAdLanding />} />
          <Route path="Wescosville" element={ <GoogleAdLanding />} />
          <Route path="Hereford" element={ <GoogleAdLanding />} />
          <Route path="Spinnerstown" element={ <GoogleAdLanding />} />
          <Route path="Limeport" element={ <GoogleAdLanding />} />
          <Route path="Allentown" element={ <GoogleAdLanding />} />
          <Route path="Fullerton" element={ <GoogleAdLanding />} />
          <Route path="Bethlehem" element={ <GoogleAdLanding />} />
          <Route path="Oreville" element={ <GoogleAdLanding />} />
          <Route path="CenterValley" element={ <GoogleAdLanding />} />
          <Route path="Boyertown" element={ <GoogleAdLanding />} />
          <Route path="Pennsburg" element={ <GoogleAdLanding />} />
          <Route path="DorneyPark" element={ <GoogleAdLanding />} />
          <Route path="Quakertown" element={ <GoogleAdLanding />} />

          <Route path="MobileMechanic" element={ <GoogleAdLanding />} />
          <Route path="LockedOut" element={ <GoogleAdLanding />} />
          <Route path="AutoLocksmith" element={ <GoogleAdLanding />} />
          <Route path="ModuleProgramming" element={ <GoogleAdLanding />} />
          <Route path="Locksmith" element={ <GoogleAdLanding />} />
          <Route path="Handyman" element={ <GoogleAdLanding />} />

          <Route path="CarNotStarting" element={ <GoogleAdLanding />} />
          <Route path="CheckEngineLight" element={ <GoogleAdLanding />} />
          <Route path="KeyNotWorking" element={ <GoogleAdLanding />} />
          <Route path="InsuranceAccepted" element={ <GoogleAdLanding />} />

          <Route path="Invitation1" element={ <GoogleAdContactUs />} />
          <Route path="Invitation2" element={ <GoogleAdContactUs />} />
          <Route path="Invitation3" element={ <GoogleAdContactUs />} />
          <Route path="Invitation4" element={ <GoogleAdContactUs />} />
          <Route path="Invitation5" element={ <GoogleAdContactUs />} />
          <Route path="Invitation6" element={ <GoogleAdContactUs />} />
          <Route path="Verification1" element={ <GoogleAdContactUs />} />
          <Route path="Verification2" element={ <GoogleAdContactUs />} />
          <Route path="Verification3" element={ <GoogleAdContactUs />} />
          <Route path="Verification4" element={ <GoogleAdContactUs />} />


          <Route path="GContactUs" element={ <GoogleAdContactUs />} />
          <Route path="GSuccess" element={ <GoogleAdSuccessPage />} />
        </Routes>
        <Contact />
        <Footer />

    </main>
  );
}

export default App;
