import React from "react";

const Intro = () => {

    return (
        <section id="Intro">
        <div className="discoverpianosection">
            <div className="container px-3">
                <div className="content">
                    <div className="header-content w-75 mx-auto text-center">
                        <h2>Proudly Serving The Lehigh Valley in Pennsylvania</h2>
                        <h5>On-Site, Same Day Locksmith Services provided by expert technicians that care about you. </h5>
                        <p className="mt-3 justify">Maintenance Services is the premier maintenance solution for all your home and automotive needs in the heart of the Lehigh Valley! We're not just another car service we're your partners in maintaining and optimizing your vehicle for best performance, all from the comfort of your own home. For Automobile repair, our mobile service brings the expertise of our certified technicians right to your doorstep, eliminating the inconvenience of traditional garage visits. Whether it's routine maintenance, diagnostics, or repairs, we're here to ensure your car runs smoothly without disrupting your busy schedule. With a commitment to quality, convenience, and customer satisfaction, Maintenance Services 610 is here to redefine the way you care for your vehicle & home.</p>
                    </div>

                </div>

            </div>
        </div>
        </section>
    )
}

export default Intro;

