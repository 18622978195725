export default function AutoRepair() {
  return (
    <section id="Intro">
    <div className="discoverpianosection">
        <div className="container px-3">
            <div className="header-content w-75 mx-auto text-center ">
              <div className="detailContainer text-center">
                <h2>Contact Us</h2>
              </div>
                    
              <div className="detailContainer ">
                <p className="detailText"><b><a href="tel:6103653313">Call us at: (610)365-3313</a></b></p>
                <img className="detailImage" alt="" src="../assets/images/phoneVector.png"></img>
              </div>

              <div className="detailContainer">
                <iframe title="GoogleMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d785.8324686414134!2d-75.48816560448452!3d40.54725453640592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c43ae42363f947%3A0xd780abbd7f666fe4!2s431%20State%20Ave%2C%20Emmaus%2C%20PA%2018049!5e0!3m2!1sen!2sus!4v1714445484049!5m2!1sen!2sus" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>

            </div>
        </div>
    </div>
    </section>
   );
 }
 
 
 